<style>
.security-code-wrapper {
  display: flex;
  align-items: center;
}
.security-code-wrapper > img {
  margin-left: 20px;
  height: 34px;
  cursor: pointer;
}
</style>

<template>
  <div class="security-code-wrapper" :style="{width: `${width}px`}">
    <Input v-model="imgCode" placeholder="请输入验证码" @on-change="$emit('update:value', imgCode)" />
    <img :src="captchaImg" alt="验证码图片加载错误" title="点击刷新验证码" @click="getCaptchaImg"/>
  </div>
</template>

<script>
import {
  initCaptcha,
  drawCodeImage,
} from "@/api/index";
export default {
  name: 'SecurityCode',
  data () {
    return {
      imgCode: '',
      captchaImg: '',
    }
  },
  props: {
    width: {
      type: [String, Number],
      default: '300'
    },
    value: {
      type: String,
      default: ''
    },
    captchaKey: {
      type: String,
      default: ''
    }
  },
  methods: {
    init () {
      this.getCaptchaImg()
    },
    getCaptchaImg() {
      initCaptcha().then(res => {
        if (res.success) {
          this.captchaImg = drawCodeImage + res.result;
          this.$emit('update:captchaKey', res.result);
        }
      });
    }
  },
  created () {
    this.init();
  }
}
</script>
